// {post.title}-{post.id} 형식의 url에서 id를 파싱하는 함수
export function parseIdFromSlug(slug: string) {
  return Number(slug.split('-').at(-1))
}

export function encodeSlug(slug: string) {
  // ex) [소규모] 합병 채권자 공고 -> 소규모-합병-채권자-공고
  const newSlug = slug.replace(/ /g, '-').replace(/[^0-9a-zA-Z가-힣-]/g, '')

  return encodeURI(newSlug)
}
